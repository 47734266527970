import React, { Component } from "react";
import { DateRangePicker } from 'react-date-range';
import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from "mdbreact";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import * as moment from 'moment';
import '../pages/Styleing.css'
class DateRangePickerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: this.props.isOpen,
            dateLabel: ''
        }

    }
    componentWillReceiveProps(newProps) {
        this.setState({ openModal: newProps.isOpen })
    }

    handleSelect = (ranges) => {
        // console.log(ranges)
        this.props.hangleDateRange(ranges.selection.startDate, ranges.selection.endDate)
    }
    closeModal = () => {
        this.setState({ openModal: false })
        this.props.hangleDateRange(null, null)
    }
    
    render() {
        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }

        return (<div>
            <MDBModal isOpen={this.state.openModal} toggle={this.closeModal} size='sm'>
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol lg='12'>
                            <DateRangePicker
                                //onChange={item => setState([item.selection])}
                                ranges={[selectionRange]}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                showDateDisplay={true}
                                onChange={this.handleSelect}
                                //ranges={state}
                                direction="horizontal"
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>

            </MDBModal>
        </div>)
    }
}
export default DateRangePickerModal